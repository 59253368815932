import React from "react";
import tw from "tailwind.macro";
import SEO from "../components/seo";
import Nav from "../components/Nav";
import styled from '@emotion/styled';
import Footer from "../components/Footer";
import ContactSection from "../components/ContactSection";
import heroImage from "../images/hero-illustration 1.png";


const Wrapper = tw.div`
  flex items-center flex-col 
`;

const HeroText = styled('h1')`
  ${tw`sm:text-3xl text-themeGreen-700`};

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 123.19%;
`;

const HeroSection = styled('div')`
  ${tw`w-full h-full sm:px-6 md:px-4 overflow-hidden`}
`

const HeroSectionContentContainer = tw.div`
  flex sm:flex-column md:flex-row w-full max-w-6xl md:pt-16 sm:flex-col relative h-full m-auto
`

const HeroSubText = styled('h3')`
  ${tw`font-sans text-sm leading-relaxed py-4 font-light flex-grow mb-8`};
  max-width: 32rem;
  font-weight: 500;
  color: #586461;
`;

const HeroSubTextInner = styled('div')`
  ${tw`mt-4`};
`;

const CTAButton = tw.a`
  bg-themeGreen-500 border border-themeGreen-500 text-xs px-8 py-4 leading-none text-white mt-4 lg:mt-0
`;

const HeroImg = styled('img')`
  max-width: 20.625rem;
`;

export default () => (
  <Wrapper>
    <SEO title="Home" />
    <Nav />
    <HeroSection>
      <HeroSectionContentContainer>
        <div>
          <HeroText>Interested in Volunteering?</HeroText>
          <HeroSubText>
            Thank you for your support and interest in our efforts. We truly appreciate it! We are working hard to get quality PPE to our heroic medical professionals as quickly as possible during these difficult times.

          <HeroSubTextInner>
              To help us work more efficiently, please fill out our volunteer form by clicking the button below.
          </HeroSubTextInner>
          </HeroSubText>
          <CTAButton href="https://docs.google.com/forms/d/e/1FAIpQLSfDMpr3QLqTv2yWhSxXsO5-6g02UgKjaqJqxHKfJDhsRGNoag/viewform">Apply</CTAButton>
        </div>

        <HeroImg src={heroImage} />
      </HeroSectionContentContainer>
    </HeroSection>

    <ContactSection />
    <Footer />
  </Wrapper>
)
